import * as client_hooks from '../../../src/hooks.client.js';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13')
];

export const server_loads = [];

export const dictionary = {
		"/": [3],
		"/basics/[state]": [~5],
		"/error": [6],
		"/nolo/legal-encyclopedia/[encyclopedia]": [~7,[2]],
		"/not-found": [8],
		"/online-divorce": [9],
		"/online-divorce/states/[state]": [~10],
		"/sentry-example": [11],
		"/states/[state]": [~12],
		"/test": [13],
		"/[article]": [~4]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';